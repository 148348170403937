.col-featured-image{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transition: all, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
      transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
}

@media (min-width: 1024px) {
    .col-featured-content.content-left {
        padding: 0 55px 0 55px;
    }
}

@media (min-width: 1024px) {
    .col-featured-content {
        padding: 0 55px 0 55px;
        flex: 1;
    }
}