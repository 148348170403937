.mw-1480 {
  width: 100%;
  max-width: 1480px !important;
}

@media (min-width: 1024px) {
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
}

.bg-color-gray {
  background-color: #f8f8f8 !important;
}

@media (min-width: 1024px) {
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
}

footer .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.color-black-link {
  color: #000 !important;
}

@media (min-width: 1024px) {
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
}

@media (min-width: 1024px) {
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
}

.color-black-link:hover {
  color: #0076a8 !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.display-size-08vw {
  font-size: 0.8vw !important;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

footer .social-links a svg {
  margin-left: 1rem;
}

footer .social-links a:hover {
  color: #0076a8 !important;
}

.color-black {
  color: #000 !important;
}

.text-lg-left {
  text-align: left !important;
}
.COPYRIGHT {
  line-height: 17.5px;
  font-size: 12px;
  font-weight: 400;
}
