.ol-featured-image {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: all, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
    transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
}

.font-slatepro-light {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: all, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
    transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
}

@media (min-width: 1024px) {
    .pb-lg-5, .py-lg-5 {
        padding-bottom: 3rem !important;
    }
}

@media (min-width: 1024px) {
    .pt-lg-5, .py-lg-5 {
        padding-top: 3rem !important;
    }
}

.bg-color-gray-white-gradient {
    background: linear-gradient(to bottom, #eee, #fff);
}

.mw-1200 {
    width: 100%;
    max-width: 1200px;
}


@media (min-width: 1024px) {
    .hp-featured-row-center {
        justify-content: center;
    }
}

.content-promo-image {
    aspect-ratio: 3 / 2;
    object-fit: cover;
    display: block;
    max-width: 100%;
    height: auto;
}

img {
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}

.display-size-11 {
    font-size: 1.125rem !important;
}

.btn-dark-blue {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    padding: 0 3.625rem 0 1.25rem;
    background-color: #002d5b;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    /* letter-spacing: .5px; */
    transition: .3s ease all;
    font-weight: 600;
}

.btn-dark-blue:after {
    content: "";
    position: absolute;
    right: -1px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #0076a8 url("https://www.tradeweb.com/Static/icons/icon-sm-arrow-right-white.svg") no-repeat center / 8px 15px;
}

.btn-dark-blue:hover, .btn-dark-blue:active, .btn-dark-blue:focus {
    background-color: #0076a8;
    color: #fff;
}