.user-trade-page{
    padding: 0 10px;
}
.min-w-324 {
    min-width: 324px !important;
}
.w-324 {
    width: 324px !important;
}


.text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / 1 ) !important;
}
.text-center {
    text-align: center !important;
}
.py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.bg-primary {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(1 102 252 / 1 ) !important;
}
.rounded-10 {
    border-radius: 10px !important;
}
.mb-18 {
    margin-bottom: 18px !important;
}


.text-14 {
    font-size: 14px !important;
}
.py-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.text-14 {
    font-size: 14px !important;
    color: #fff;
}

.font-bold {
    font-weight: 700 !important;
}
.text-lg {
    font-size: 28px !important;
}


.box-shadow {
    box-shadow: 0 0 10px #a1a1a11f;
}
.p-20 {
    padding: 20px !important;
}
.bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / 1 ) !important;
}
.rounded-10 {
    border-radius: 10px !important;
}
.box-border {
    box-sizing: border-box !important;
}

.text-kline-up {
    --tw-text-opacity: 1 !important;
    color: rgb(1 102 252 / 1 ) !important;
}
.text-xs {
    font-size: 20px !important;
}

.mt-10 {
    margin-top: 10px !important;
}
.justify-between {
    justify-content: space-between !important;
}
.items-center {
    align-items: center !important;
}
.flex {
    display: flex !important;
}
.mt-8 {
    margin-top: 8px !important;
    color: #fff;
}



.buy-btn{
    padding: 10px 50px;
    background-color: #0166FC;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    border-radius: 20px;

}

.buy-btn:disabled{
    background-color: #3582f6;
    cursor: not-allowed;

}


.sell-btn{
    padding: 10px 50px;
    background-color: #F23C48;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    border-radius: 20px;

}

.sell-btn:disabled{
    background-color: #f56b74;
    cursor: not-allowed;

}

.el-select input::placeholder{
    color: #a5a0a0;
}







/* plus minus */
.trade-quantity {
    display: flex;
    align-items: center;
  }
.trade-quantity  .btn {
    cursor: pointer;
    background-color: #13092f;
    outline: none;
    color: #fff;
    border-radius: 0;
  }
.trade-quantity  input {
   border-radius: 0;
  }
  .trade-dis-btn{
    display: flex;
    align-items: center;

    justify-content: space-between;
  }
  .form-switch input{
    cursor: pointer;
  }

  .form-control:disabled {
    background-color: #201247;
    opacity: 1;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    border-color: none !important;
    border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.tv-lightweight-charts svg{
    display: none !important;
}


  
.trade-results-modal-area .modal-header {
    background-color: #13092f;
    justify-content: end;
    align-items: center;
    color: #fff;
    border-bottom: none !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .trade-results-modal-area .modal-content {
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  }
  
  .trade-results-modal-area .timer .value {
    font-size: 30px;
    font-weight: 600;
    color: #c3c5c9;
  }
  
  .trade-timer {
    /* width: 25%;
    margin: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .trade-results {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .trade-results p {
    font-size: 16px;
    color: #fff;
    margin: 0 !important;
  }


  .trade-results-modal-close-btn svg{
    cursor: pointer;
    color: #F23C48;
  }