.js-sr-up{
    visibility: visible;
          opacity: 0;
          transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}

.slick-track{
    opacity: 1;
                width: 0px;
                transform: translate3d(0px, 0px, 0px);
}

.bg-size-cover {
    background-size: cover;
    background-position: center;
}

.pb-6 {
    padding-bottom: 60px !important;
}

.pt-6 {
    padding-top: 60px !important;
}

@media (min-width: 992px) {
    .bg-attachment-fixed-lg {
        background-attachment: fixed !important;
    }
}

.mw-960 {
    max-width: 960px !important;
}

@media (min-width: 768px) {
    .d-md-block {
        display: block !important;
    }
}

.display-size-3 {
    font-size: 3.125rem !important;
    font-weight: 500;
}

.display-size-13 {
    font-size: .875rem !important;
    font-weight: 500;
}