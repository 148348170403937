.user-deposit-section .card{
    background-color: transparent  ;
}

.user-deposit-section .card-body{
   padding: 0;
}


.gateways-data-area {
    text-align: center;
  }
  
  .deposit-qr-code-area {
  width: 65%;
  margin: auto;
  border: 6px solid #0075BB;
  border-radius: 30px;
  padding: 20px;
  position: relative;
}

.deposit-qr-code-area .deposit-scan-me{
    padding-top: 20px;
}
.deposit-qr-code-area h6{
    font-size: 20px;
    background-color: #0075BB;
    color: #fff;
    padding: 10px 40px;
    border-radius: 50px;
}

.deposit-qr-code {
  position: relative;
  padding: 15px;
}

.deposit-qr-code__angle:before,
.deposit-qr-code__angle:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: #0075BB;
  border-width: 0;
}

.deposit-qr-code__angle:before {
  border-left-width: 6px;
  left: 0;
}

.deposit-qr-code__angle:after {
  border-right-width: 6px;
  right: 0;
}

.deposit-qr-code__angle--top:before,
.deposit-qr-code__angle--top:after {
  top: 0;
  border-top-width: 6px;
}

.deposit-qr-code__angle--bottom:before,
.deposit-qr-code__angle--bottom:after {
  bottom: 5px;
  border-bottom-width: 6px;
}

.deposit-qr-code__angle--top:before {
  border-top-left-radius: 4px;
}

.deposit-qr-code__angle--top:after {
  border-top-right-radius: 4px;
}

.deposit-qr-code__angle--bottom:before {
  border-bottom-left-radius: 4px;
}

.deposit-qr-code__angle--bottom:after {
  border-bottom-right-radius: 4px;
}




  .gateways-data-area img {
    width: 100%;
    height: auto;
  }
  .gateways-data-area .wallet-address  {
    padding-top: 10px;
  }
  .gateways-data-area .wallet-address span {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
  }
  .gateways-data-area .wallet-address p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #87909f;
  }
  .wallet-address-copy {
    display: inline;
  }
  
  .gateways-data-area  .wallet-address-copy svg {
    cursor: pointer;
    font-size: 20px;
    color: #0166fc;
  }

 
 
  @media (min-width: 0px) and (max-width: 980px) {
    .deposit-qr-code-area {
        width: 85%;
       
      }
  }



  /* file */

  
/**/

.deposit-input-file{ 
    position:relative; 
    overflow:hidden;  
    padding:0;
    display:block;
    max-width:100%;
    cursor:pointer;
    display: inline-block;
  }
  
  .deposit-input-file .deposit-input-file-btn { 
    white-space:nowrap; 
    display:inline-block;
    margin-right:1em;
    vertical-align:top;
    background-color: #13092F;
    padding: 10px 30px ;
    border-radius: 10px;
    color: #fff;
  }
  
  .deposit-input-file .deposit-input-file-icons {
    float:left;
    font-size:16px; 
    line-height:inherit;
    margin-right:4px;
  }
  
  .deposit-input-file ins { 
    white-space:nowrap; 
    display: block;
    max-width:100%; 
    overflow:hidden; 
    text-overflow:ellipsis; 
    font-size:12px;
  }
  
  .deposit-input-file:after {
    content:""; 
    display:block; clear:both; 
  } 
  
  .deposit-input-file input { 
    width:0.1px; 
    height:0.1px;
    opacity:0;
    overflow:hidden;
    position:absolute; 
    top:-100px;
    z-index:-1; 
  }
  