.offcanvas-menu-area{
    width: 100%;
    background-color: #000;
    background-image: url("./nav-our-business-bg.webp");
    background-size: cover;
    height: 100%;
    z-index: 1;
    --bs-offcanvas-width: 100%;
}

@media (min-width: 991px) {
    .mt-lg-160 {
        margin-top: 160px !important;
    }
}

 .mega-menu-content .menu-board {
    height: calc(100vh - 150px) !important;
    overflow-y: auto !important;
}

.offcanvas-backdrop{
    z-index: 1;
}

@media (min-width: 576px) {
     .mega-menu .mega-menu-tabs {
        margin-right: 30px;
    }
}

@media (min-width: 992px) {
     .mega-menu li {
        border-top: 1px solid #fff;
    }
}

 .mega-menu li {
    padding: 15px 0 20px;
}

 li {
    list-style: none;
}

@media (min-width: 1024px) {
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

 a.mega-menu-item-active,  button.mega-menu-item-active {
    color: #00b6de !important;
}

.btn-link {
    font-weight: 400;
    color: #002d5b;
    background-color: transparent;
}

.go {
    color: #00b6de;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 2px;
    border: 3px solid rgba(255, 255, 255, .2);
    text-align: center !important;
}

.ls-05 {
    letter-spacing: .5px;
}

.lh-1_2 {
    line-height: 1.2 !important;
}

.display-size-8 {
    font-size: 1.2rem !important;
    font-weight: 500;

}

nav.menu h5, nav.menu .h5 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500 !important;
    color: #00b6de !important;

}

nav.menu a, nav.menu button {
    color: #fff !important;
}

nav.menu h3, nav.menu .h3 {
    font-size: 22px;
    line-height: 22px;
    font-weight: 400 !important;
    letter-spacing: 1px;
}

@media (min-width: 1024px) {
    .mb-lg-3, .my-lg-3 {
        margin-bottom: 1rem !important;
    }
}

nav.menu a:hover, nav.menu a:active, nav.menu a:focus, nav.menu button:hover, nav.menu button:active, nav.menu button:focus {
    color: #00b6de !important;
}

nav.menu h5:hover, nav.menu .h5:hover {
    color: #fff !important;
}