.navbar {
  /* background-color: #fff; */
  --bs-navbar-padding-y: 0;
  z-index: 99999;
}
.page-logo {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: transform 0.5s cubic-bezier(0.5, 0, 0, 1),
    opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 0.75s,
    transform 0.5s cubic-bezier(0.5, 0, 0, 1) 0.75s;
}

.search-box {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 1s,
    transform 0.5s cubic-bezier(0.5, 0, 0, 1) 1s;
}

.js-sr-intro-3a-1-1 {
  visibility: visible;
  opacity: 0;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}

.js-sr-intro-3a-2 {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 1.25s,
    transform 0.5s cubic-bezier(0.5, 0, 0, 1) 1.25s;
}

nav.navbar .navbar-brand-container {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

nav.navbar .container {
  transition: width 0.5s ease-in-out, max-width 0.5s ease-in-out,
    margin 0.5s ease-in-out, padding 0.5s ease-in-out, opacity 0.2s ease-in-out;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1080px;
}

@media (min-width: 1024px) {
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
}

@media (min-width: 1080px) {
  nav.navbar .navbar-brand {
    transition: transform 0.5s cubic-bezier(0.5, 0, 0, 1) !important;
  }
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.34375rem;
  padding-bottom: 0.34375rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

nav.navbar .navbar-brand svg {
  width: 153px;
  height: 40px;
  transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
}

nav.navbar .navbar-nav {
  align-items: center;
}

.flex-row {
  flex-direction: row !important;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

nav.navbar .nav-item form {
  position: absolute;
  top: 0;
  right: 0;
  padding-bottom: 10px;
  padding-left: 12px;
  line-height: 18px;
  opacity: 0;
  text-align: right;
  width: 0;
  transition: all 0.2s ease-in-out;
  z-index: 10;
  pointer-events: none;
  flex-flow: row;
  display: none;
}

nav.navbar .nav-item {
  position: relative;
  margin-left: 40px;
}

nav.navbar .nav-item a.nav-link.search-icon,
nav.navbar .nav-item button.nav-link.search-icon,
nav.navbar .nav-item span.js-search-open.nav-link.search-icon {
  padding: 7px 0 !important;
  position: relative;
  z-index: 10;
}

nav.navbar .nav-item a.nav-link,
nav.navbar .nav-item button.nav-link,
nav.navbar .nav-item span.js-search-open.nav-link {
  display: inline-block;
  padding: 0;
  transition: transform 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
}

nav.navbar .nav-item a,
nav.navbar .nav-item button,
nav.navbar .nav-item span.js-search-open {
  color: #003d7d;
}

.navbar .open .hamburger-icon .line-1 {
  transform: translateX(3px) rotate(45deg);
}
.navbar .open .hamburger-icon rect {
  fill: #fff;
}
nav .hamburger-icon rect {
  fill: #002d5b;
  transition: all 0.5s ease-in-out;
}

.navbar .open .hamburger-icon .line-2 {
  opacity: 0;
}

.navbar .open .hamburger-icon .line-3 {
  transform: translate(3px, 3px) rotate(-45deg);
  transform-origin: bottom left;
}

nav .hamburger-icon svg {
  height: 30px;
}

.open svg {
  color: #fff !important;
}
.open circle {
  stroke: #fff !important;
}
.open rect {
  fill: #fff !important;
}
.open path {
  fill: #fff !important;
}

/* @media (min-width: 1080px) {
    .navbar.open .container {
        justify-content: flex-end;
    }
} */

.open .navbar-brand {
  transform: translateX(calc((100vw - 1080px) / -2 + 15px)) !important;
}

.logo-color svg {
  color: #fff !important;
}
.logo-color circle {
  stroke: #fff !important;
}
.logo-color rect {
  fill: #fff !important;
}
.logo-color path {
  fill: #fff !important;
}


.navbar-is-fixed{
  background-color: rgba(255,255,255,.85);
}

.navbar-is-fixed .navbar-brand svg {
  width: 132px !important;
  height: 35px !important;
}

.navbar-is-fixed .navbar-brand-container {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

@media (min-width: 991px) {
  .mt-lg-160 {
      margin-top: 160px !important;
  }
}