.trade-order-history-section {
  padding: 2vw 3vw;
}

.trade-order-history-menu-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trade-order-history-menu {
  display: flex;
  align-items: center;
}
.trade-order-history-menu p {
  font-size: 1vw;
  color: #b2b3ba;
  margin-right: 2vw;
  cursor: pointer;
}
.trade-order-history-menu .active {
  color: rgb(1 102 252 / 1);
}
.trade-order-history-menu-dis{
  display: flex;
  align-items: center;
}
.trade-order-history-menu-dis p {
  font-size: 1vw;
  color: #b2b3ba;
}
.trade-order-history-menu-dis p:nth-child(1) {
 padding-right: 20px;
}
.trade-order-history-menu-dis p .profit-up {
  color: rgb(1 102 252 / 1);
  padding-left: 0.2vw;
}
.trade-order-history-menu-dis p .profit-dwon {
  color: #f23c48;
  padding-left: 0.2vw;
}

.trade-order-history-area {
  padding: 1.5vw 0;
  text-align: center;
}
.trade-order-history-area table {
  width: 100%;
}

.trade-order-history-area thead {
  padding-bottom: 2vw;
}
.trade-order-history-area th {
  font-size: 1vw;
  color: #b2b3ba;
  font-weight: 500;
}
.trade-order-history-area tr {
  padding-bottom: 2vw;
  line-height: 35px;
}
.trade-order-history-area tr td {
  font-size: 1vw;
  color: #fff;
  font-weight: 400;
}

.trade-order-history-buy-btn {
    padding: 0.2vw .8vw;
  background-color: #0166fc;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 20px;
  display: inline;
}
.trade-order-history-sell-btn {
  padding: 0.2vw .8vw;
  background-color: #f23c48;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 20px;
  display: inline;
}

.trade-order-history-profit-up {
  color: #0166fc;
}
.trade-order-history-profit-dwon {
  color: #f23c48;
}

.trade-order-history-close-btn {
  padding: 0.2vw 1vw;
  background-color: #f23c481a;
  color: #f23c48;
  border-radius: 20px;
}

.trade-order-history-area button:disabled{
  background-color: #13092f;
}

.trade-order-history-tp-sl{
  padding: 0.2vw .8vw;
  --tw-bg-opacity: 1 ;
  background-color: #13092F;
  --tw-text-opacity: 1 ;
  color: rgb(1 102 252 / 1) ;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 20px;
  display: inline;
  cursor: pointer;
}

.user-oder-tp-sl-modal .modal-content {
 min-height: 100% !important;
padding-bottom: 3vw !important;
}