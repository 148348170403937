.RTF .container .row .col-12 h3 {
    font-size: 32px !important;
    line-height: 40px !important;
}

.display-size-11 h3{
    font-size: 1.125rem !important;
    padding-bottom: 10px;
}

.RTF p{
    color: #000;
}

.ClientsAndMarkets{
    padding: 50px 0;
}

.RTF a{
    text-decoration: underline ;

}