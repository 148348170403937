.user-manu-area{
    padding: 12px 0;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0;
    z-index: 999;
    background-color: #1d113d ;
}
.user-manu-area .logo img{
    width: auto;
    height: 40px;
  }
  .user-manu-area .nav-item {
    font-size: 16px;
    font-weight: 600;
    padding: 0 5px;
  }
  
  .user-manu-area  .nav-item a {
    text-decoration: none;
    color: #fff;
    position: relative;
    padding: 5px 10px;
    border-radius: 8px;
    letter-spacing: 1px;
  }
  
  .user-manu-area  .nav-item .active{
    color: #0166FC;
  }
  
  .user-manu-area ul li a::before {
    content: attr(data-text);
    position: absolute;
    color: #0166FC;
    width: 0%;
    overflow: hidden;
    transition: 2.5s;
    letter-spacing: 1px;
  }
  
  .user-manu-area  ul li a:hover::before {
    width: 100%;
    -webkit-text-stroke: 1px #0166FC;
  }
  
  .user-manu-area  .user-manu-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .user-manu-area .user-avatar img{
    height: 40px;
    width: auto;
    border-radius: 50%;
    cursor: pointer;
}

.custom-container{
  padding: 0 40px;
}

.user-manu-logo .logo svg{
  width: 150px;
  height: auto;
}
