.dashboard-trade-market-section{
  padding: 0 10px;
}
.dashboard-trade-market-section .box-shadow {
  box-shadow: 0 0 10px #a1a1a11f;
}
.dashboard-trade-market-section .rounded-10 {
  border-radius: 10px !important;
}

.dashboard-trade-market-section .ml-10 {
  margin-left: 10px !important;
}
.dashboard-trade-market-section .p-10 {
  padding: 10px !important;
}
.dashboard-trade-market-section .p-10 {
  padding: 10px !important;
}
.dashboard-trade-market-section .h-700 {
  height: 700px !important;
}
.dashboard-trade-market-section .bottom-border {
  border: 0 solid #13092F;
  border-bottom-width: 1px;
}
.dashboard-trade-market-section .py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.dashboard-trade-market-section .px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.dashboard-trade-market-section .cursor-pointer {
  cursor: pointer !important;
}
.dashboard-trade-market-section .flex-1 {
  flex: 1 1 0% !important;
}
.dashboard-trade-market-section .w-36 {
  width: 36px !important;
}

.dashboard-trade-market-section img {
  height: 36px !important;
  width: auto;
  border-radius: 50%;
}
.dashboard-trade-market-section .pl-10 {
  padding-left: 10px !important;
  color: #fff;
}
.dashboard-trade-market-section .text-kline-down {
  --tw-text-opacity: 1 !important;
  color: rgb(242 60 72 / 1) !important;
}

.dashboard-trade-market-section .text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / 1) !important;
}
.dashboard-trade-market-section .text-12 {
  font-size: 12px !important;
}
.dashboard-trade-market-section .py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.dashboard-trade-market-section .rounded-8 {
  border-radius: 8px !important;
}

.dashboard-trade-market-section .w-80 {
  width: 80px !important;
}

.dashboard-trade-market-section .hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: #13092F !important;
}

.dashboard-trade-market-section .flex {
  display: flex !important;
}
.dashboard-trade-market-section .base-input {
  border-radius: 20px;
  background: #13092F;
  padding: 8px 0;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  color: #fff;
}
.dashboard-trade-market-section .w-full {
  width: 100% !important;
}

.dashboard-trade-market-section .mr-10 {
  margin-right: 10px !important;
}
.dashboard-trade-market-section .ml-16 {
  margin-left: 16px !important;
}

.dashboard-trade-market-section .el-icon {
  --color: inherit;
  height: 1em;
  width: 1em;
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  fill: currentColor;
  color: var(--color);
  font-size: inherit;
}

.dashboard-trade-market-section .el-icon svg {
  height: 1em;
  width: 1em;
}
.dashboard-trade-market-section .base-input input {
  height: 100%;
  width: 100%;
  background-color: transparent;
  color: #fff;
  border: none;
}
.dashboard-trade-market-section .base-input input:focus {
  outline: none;
}

.dashboard-trade-market-section .pr-20 {
  padding-right: 20px !important;
}

.dashboard-trade-market-section .trade-type {
  --el-select-input-focus-border-color: none !important;
}
.dashboard-trade-market-section .el-select .el-input {
  display: flex;
}

.dashboard-trade-market-section .trade-type .el-input__wrapper {
  border-radius: 20px;
  box-shadow: none !important;
}

.dashboard-trade-market-section .trade-type .el-input__wrapper select {
  width: 105px;
  background-color: #13092F !important;
  color: #fff;
  border: none;
}

.dashboard-trade-market-section .bg-kline-up {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(1 102 252 / 1) !important;
  height: fit-content;
  text-align: center;
}

.dashboard-trade-market-section .bg-kline-down {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(242 60 72 / 1) !important;
  height: fit-content;
  text-align: center;
}

.dashboard-trade-market-section
  .dashboard-trade-market-area::-webkit-scrollbar {
  width: 0;
}
.dashboard-trade-market-section
  .dashboard-trade-market-area .text-kline-up {
 color: #898585;
}


.closed-market-btn{
  font-size: .9vw;
  color: #cbcbcb;
  --tw-bg-opacity: 1;
  background-color: #1d113d;
  padding: .8vw 1.2vw;
  border-radius: .5vw;
}