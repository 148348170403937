.jumbotron-3 {
  visibility: visible;
  opacity: 1;
  transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 0s;
}

.display-size-1 {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 0.25s,
    transform 0.5s cubic-bezier(0.5, 0, 0, 1) 0.25s;
}

.display-size-88 {
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 0.25s,
    transform 0.5s cubic-bezier(0.5, 0, 0, 1) 0.25s;
}

.js-sr-intro-2-1{

    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 0.5s,
      transform 0.5s cubic-bezier(0.5, 0, 0, 1) 0.5s;
}

.js-sr-intro-3b-1{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 1s,
      transform 0.5s cubic-bezier(0.5, 0, 0, 1) 1s;
}

.js-sr-intro-3b{
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: all, opacity 0.5s cubic-bezier(0.5, 0, 0, 1) 0.75s,
      transform 0.5s cubic-bezier(0.5, 0, 0, 1) 0.75s;
}

.bg-size-cover {
    background-size: cover;
    background-position: center;
}

@media (min-width: 1024px) {
    .mnh-600 {
        min-height: 600px !important;
    }
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-column {
    flex-direction: column !important;
}

.mnw-100 {
    min-width: 100%;
}

.mnh-100 {
    min-height: 100%;
}

.position-absolute {
    position: absolute !important;
}

#homeContainerFlex {
    flex: 1;
}
.z-index-1 {
    z-index: 1;
}

@media (min-width: 768px) {
    .mb-md-5, .my-md-5 {
        margin-bottom: 3rem !important;
    }
}

.align-self-center {
    align-self: center !important;
}

@media (min-width: 1024px) {
    .TopBannar-container {
        max-width: 960px;
    }
}

.color-white {
    color: #fff !important;
}

.text-transform-none {
    text-transform: none !important;
}

.ls-04 {
    letter-spacing: .4px;
}

.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}

.display-size-1 {
    font-size: 3.625rem !important;
}

.ls-05 {
    letter-spacing: .5px;
}

.display-size-8 {
    font-size: 1.5rem !important;
}

@media (min-width: 576px) {
    .mt-md-80 {
        margin-top: 80px !important;
        padding-bottom: 50px;
    }
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn-marine-blue {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    padding: 0 3.625rem 0 1.25rem;
    background-color: #002d5b;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .5px;
    transition: .3s ease all;
    font-weight: 500;
    line-height: 1.25;
}


.btn-marine-blue:after {
    content: "";
    position: absolute;
    right: -1px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #fff url("https://www.tradeweb.com/Static/icons/icon-sm-arrow-right-navy.svg") no-repeat center / 8px 15px;
}

.btn-marine-blue:hover, .btn-marine-blue:active, .btn-marine-blue:focus {
    background-color: #0076a8;
    color: #fff;
}

.opacity-04 {
    opacity: .4;
}

.h-100 {
    height: 100% !important;
}

.position-absolute-bl {
    position: absolute;
    bottom: 0;
    left: 0;
}

.bg-color-turquoise-green-gradeint {
    background-image: linear-gradient(90deg, #78be20 -4%, #00b6de 103%);
}

.pb-2, .py-2 {
    padding-bottom: .5rem !important;
}

.pt-2, .py-2 {
    padding-top: .5rem !important;
}

.display-size-10 {
    font-size: 1.25rem !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}
.ls-05 {
    letter-spacing: .5px;
}

@media (min-width: 1024px) {
    .pt-lg-4, .py-lg-4 {
        padding-top: 1.5rem !important;
    }
}

@media (min-width: 1024px) {
    .pb-lg-3, .py-lg-3 {
        padding-bottom: 1rem !important;
    }
}

@media (min-width: 1025px) {
    .border-lg-right {
        border-right: 1px solid #d8d8d8 !important;
    }
}

.border-white {
    border-color: #fff !important;
}

h4, .h4 {
    font-size: 1.5rem;
}

.ls-04 {
    letter-spacing: .4px;
}

.p-0 {
    padding: 0 !important;
}


.btn-link {
    font-weight: 400;
    color: #002d5b;
    background-color: transparent;
}

a .arrow:before, button .arrow:before {
    content: "";
    display: inline-block;
    position: absolute;
    overflow: hidden;
    width: 0;
    transition: width .2s ease-in-out, transform .2s ease-in-out;
}

a .arrow, button .arrow {
    display: inline-block;
    font-size: 13px;
    text-align: left;
    transform: translateX(0);
    transition: transform .2s ease-in-out;
    margin-left: 10px;
}