.display-size-2-sm{
    visibility: visible;
            opacity: 1;
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transition: all, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s,
              transform 1s cubic-bezier(0.5, 0, 0, 1) 0s;
}

.display-size-2 {
    font-size: 3.25rem !important;
}

.color-gray {
    color: #706f73 !important;
}

.rounded-2xl {
    border-radius: 16px;
}

.pl-3, .px-3 {
    padding-left: 1rem !important;
}


.pr-3, .px-3 {
    padding-right: 1rem !important;
}
.pb-2, .py-2 {
    padding-bottom: .5rem !important;
}
.pt-2, .py-2 {
    padding-top: .5rem !important;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem !important;
}

.bg-color-dark-pink {
    background-color: #b12e6a !important;
}

.border-color-white {
    border-color: #fff !important;
}

.ls-09 {
    letter-spacing: .9px;
}

.pr-2, .px-2 {
    padding-right: .5rem !important;
}

.mr-2, .mx-2 {
    margin-right: .5rem !important;
}

.border-right {
    border-right: 1px solid #d8d8d8 !important;
}

.display-size-13 {
    font-size: .875rem !important;
}

.related-news-block>.container {
    margin-bottom: 60px;
}

.display-size-11 {
    font-size: 1.125rem !important;
}

.related-news-card .link.new-link .title {
    transition: color .3s ease;
}

.display-size-9 {
    font-size: 1.375rem !important;
}

.related-news-card a:hover {
    color: #706f73 !important;
}

.btn-transparent-white {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 40px;
    padding: 0 2.5rem 0 1.25rem;
    border-radius: 20px;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .5px;
    border: 1px solid #fff;
    transition: .3s ease all;
}

.btn-transparent-white:hover, .btn-transparent-white:active, .btn-transparent-white:focus {
    border: 1px solid #fff;
    background-color: #fff;
    color: #002d5b;
}

.btn-transparent-white:after {
    content: "";
    position: absolute;
    right: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: transparent url("https://www.tradeweb.com/Static/icons/icon-sm-arrow-right-white.svg") no-repeat center / 8px 15px;
    transition: .3s ease all;
}

.btn-transparent-white:hover:after, .btn-transparent-white:active:after, .btn-transparent-white:focus:after {
    background-image: url("https://www.tradeweb.com/Static/icons/icon-sm-arrow-right-navy.svg");
}